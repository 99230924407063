import { graphql, PageProps } from "gatsby"
import { withoutAuthentication } from "@app/hoc/Authentication"
import Page from "@app/components/Account/LoginRegister/AccountAuth"

export type Props = PageProps<GatsbyTypes.PageAccountLoginAuthQuery, GatsbyTypes.PageAccountLoginAuthQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageAccountLoginAuth {
    page: sanityPageAccountLoginAndRegister {
      titleLogin
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default withoutAuthentication(Component)

import { useState, useEffect } from "react"
import { Container, Heading } from "@chakra-ui/react"
import { useCustomerContext } from "@app/providers/customer"
import { memo } from "react"

import type { Props } from "@app/pages/account/login/auth"
import type { PageProps } from "@root/types/global"
import { useCartContext } from "@app/providers/cart"

const AccountAuth: React.FC<PageProps<Props>> = ({ location }) => {
  const LOADING_STATES = ["Verifying Account...", "Loading Customer Details...", "Completing Login..."]
  const [status, setStatus] = useState(LOADING_STATES[0])
  const { customer } = useCustomerContext()
  const { gotoCart } = useCartContext()

  useEffect(() => {
    if (customer?.id) {
      gotoCart()
    }
  }, [location, customer, gotoCart])

  useEffect(() => {
    setTimeout(() => {
      setStatus(LOADING_STATES[1])
      setTimeout(() => {
        setStatus(LOADING_STATES[2])
      }, 3000)
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Heading as="h3" textAlign="center" py={15} pb={9}>
        {status}
      </Heading>
    </Container>
  )
}

export default memo(AccountAuth)
